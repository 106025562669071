import { useState, useCallback, memo } from "react";
import { Stack, Link, useDisclosure } from "@chakra-ui/react";
import Image from "next/image";
import {
  ANDROID_BUTTON_ALT,
  ANDROID_BUTTON_ID,
  ANDROID_LINK_ID,
  IOS,
  IOS_BUTTON_ALT,
  IOS_BUTTON_ID,
  IOS_LINK_ID,
} from "utils/constants";

import appleStoreIcon from "../public/download_apple_app.png";
import playStoreIcon from "../public/download_android_app.png";
import ModalContainer from "./ModalContainer";
import EmailNotificationForm from "./EmailNotificationForm";

function AppDownloadButton({ store }) {
  const { isOpen, onToggle } = useDisclosure();
  const [title, setTitle] = useState("");
  const [target, setTarget] = useState("");

  const onOpenModal = useCallback((target) => {
    setTitle(`WellFitly ${target} App Download`);
    setTarget(target);
    onToggle();
  }, [onToggle]);

  return (
    <>
      <Stack>
        <Link
          id={store === IOS ? IOS_LINK_ID : ANDROID_LINK_ID}
          onClick={() => onOpenModal(store)}
        >
          <Image
            id={store === IOS ? IOS_BUTTON_ID : ANDROID_BUTTON_ID}
            src={store === IOS ? appleStoreIcon : playStoreIcon}
            alt={store === IOS ? IOS_BUTTON_ALT : ANDROID_BUTTON_ALT}
          />
        </Link>
      </Stack>
      <ModalContainer isOpen={isOpen} onClose={onToggle} title={title}>
        <EmailNotificationForm onClose={onToggle} target={target} />
      </ModalContainer>
    </>
  );
}

export default memo(AppDownloadButton);
