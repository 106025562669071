import { memo } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import ListHeader from "components/ListHeader";
import { ANDROID, IOS } from "utils/constants";
import AppDownloadButton from "./AppDownloadButton";

const SocialButton = memo(({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
});

SocialButton.displayName = 'SocialButton';

function Footer() {
  return (
    <Box
      bg={useColorModeValue("black", "gray.900")}
      color={useColorModeValue("white", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>Resources</ListHeader>
            <Link href={"/getting-started"}>Getting Started</Link>
            <Link href={"/providers"}>Find Professionals</Link>
            <Link href={"/blog"}>WellFitly Blog</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Link href={"https://wa.me/2348130900268"} target="_blank">
              On WhatsApp
            </Link>
            <Link href={"https://telegram.me/wellfitly"} target="_blank">
              On Telegram
            </Link>
            <Link href={"mailto:hello@wellfitly.com"}>Email us</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Legal</ListHeader>
            <Link href={"/cookies"}>Cookies Policy</Link>
            <Link href={"/privacy"}>Privacy Policy</Link>
            <Link href={"/terms"}>Terms of Service</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Install WellFitly App</ListHeader>
            <AppDownloadButton store={IOS} />
            <AppDownloadButton store={ANDROID} />
          </Stack>
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.700", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ md: "space-between" }}
          align={{ md: "center" }}
        >
          <Text>
            © {new Date().getFullYear()} WellFitly&#8482;. All rights reserved
          </Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Facebook"}
              href={"https://facebook.com/wellfitly"}
            >
              <FaFacebook />
            </SocialButton>
            <SocialButton
              label={"Instagram"}
              href={"https://instagram.com/wellfitly"}
            >
              <FaInstagram />
            </SocialButton>
            <SocialButton
              label={"Twitter"}
              href={"https://twitter.com/wellfitly"}
            >
              <FaTwitter />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default memo(Footer);
