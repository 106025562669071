import { Text } from "@chakra-ui/react";
import { sentenceCase } from "utils/textFormatter";

const ErrorText = ({ text }) => (
  <Text color={"red.300"} fontSize={"sm"}>
    {sentenceCase(text)}
  </Text>
);

export default ErrorText;
