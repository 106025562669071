import { useState, memo } from "react";
import { useRouter } from "next/router";
const { Box, Text, Input, Button, Stack } = require("@chakra-ui/react");

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorText from "./shared/typography/ErrorText";
import { supabase } from "utils/supabase";
import * as ga from "utils/ga";
import { COLLECTIONS, FIELDS, IOS } from "utils/constants";
import useOsName from "hooks/useOsName";
import appleStoreIcon from "../public/app-store.png";
import playStoreIcon from "../public/playstore.png";
import checkIcon from "../public/checked.png";
import Image from "next/image";

const formSchema = yup.object().shape({
  email: yup.string().email().trim().required(),
});

const EmailNotificationForm = ({ target }) => {
  const router = useRouter();
  const { os: osName } = useOsName();
  const [isLoading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;

  const canSubscribeToNotification = async (email) => {
    try {
      const { data } = await supabase
        .from(COLLECTIONS.notifications_subscription)
        .select(FIELDS.email)
        .eq(FIELDS.email, email);
      if (data.length) {
        ga.event({
          action: "subscription_exist_error",
        });
        return false;
      }
      return true;
    } catch (error) {
      ga.event({
        action: "subscription_verify_error",
      });
      throw error;
    }
  };

  const subscribeToNotification = async (payload) => {
    try {
      await supabase
        .from(COLLECTIONS.notifications_subscription)
        .insert([payload]);
      ga.event({
        action: "app_notification_subscription",
      });
      setFormMessage(
        "Thank for your for subscribing to WellFitly app release notification. Keep an eye for us in your inbox."
      );
      setIsFormSubmitted(true);
    } catch (error) {
      throw error;
    }
  };

  const onSubmitForm = async ({ email }) => {
    setLoading(true);
    setFormMessage(null);
    try {
      const payload = {
        email,
        attribution: `${target} Store Button`,
        type: "App Release Notification",
        device: osName,
        page_url: router.pathname,
      };
      const canSubscribe = await canSubscribeToNotification(email);
      if (canSubscribe) {
        await subscribeToNotification(payload);
      } else {
        ga.event({
          action: "app_notification_subscription_existed",
        });
        setFormMessage("Release notification already exist for this email");
        setIsFormSubmitted(true);
        setLoading(false);
      }
    } catch (error) {
      ga.event({
        action: "on_app_notification_subscription_error",
      });
      setLoading(false);
      setFormMessage(error.message);
    }
  };

  return (
    <>
      {isFormSubmitted ? (
        <Stack alignItems={"center"}>
          <Image
            layout="fixed"
            placeholder="blur"
            width={80}
            height={80}
            src={checkIcon}
            alt="WellFitly"
          />
          <Box pb="30">{formMessage}</Box>
        </Stack>
      ) : (
        <Stack alignItems={"center"}>
          <Image
            src={target === IOS ? appleStoreIcon : playStoreIcon}
            placeholder="blur"
            layout="fixed"
            width={80}
            height={80}
            alt="WellFitly"
          />
          <Box pb="30" as={"form"} onSubmit={handleSubmit(onSubmitForm)}>
            <Text pb="4">
              Wellfitly {target} app is coming soon. Enter your email to get
              release notification.
            </Text>
            <Stack spacing={4}>
              <Input
                type="email"
                placeholder="Email Address"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
                isInvalid={errors.email}
                errorBorderColor="red.300"
                {...register("email")}
              />
              <Button
                id="btn-app-notify"
                type="submit"
                disabled={isLoading}
                rounded={"full"}
                px={6}
                colorScheme={"black"}
                bg={"black"}
                _hover={{ bg: "gray.400", color: "black" }}
              >
                NOTIFY ME
              </Button>
              {errors.email && <ErrorText text={errors.email.message} />}
              {formMessage && <ErrorText text={formMessage} />}
            </Stack>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default memo(EmailNotificationForm);
