export const titleCase = (str) => {
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const sentenceCase = (str) => {
  return `${str[0].toUpperCase()}${str.slice(1, str.length)}`;
};

export const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const _date = new Date(date);
  const dateString = `${
    monthNames[_date.getMonth()]
  } ${_date.getDate()}, ${_date.getFullYear()}`;
  return dateString;
};

export const formatSlug = (slug) => {
  if (!slug) return "Best place to find Wellness & Fitness Professionals";
  return titleCase(slug.split("-").join(" "));
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const truncateString = (text, size) => {
  if (text.length > size) {
    return text.substring(0, size - 3) + "...";
  }
  return text;
};

export const slugToTitleCase = (slug) => {
  // Split the slug by hyphens
  const words = slug.split("-");

  // Map through the words and capitalize the first letter of each word
  const titleCasedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the words with spaces
  return titleCasedWords.join(" ");
};
