import { memo } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useSession } from "@supabase/auth-helpers-react";

const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About WellFitly",
    href: "/about",
  },
  {
    label: "Getting Started",
    children: [
      {
        label: "For Professionals",
        subLabel: "Getting listed on WellFitly",
        href: "/getting-started/providers",
      },
      {
        label: "For Clients",
        subLabel: "Finding professionals on WellFitly",
        href: "/getting-started/individuals",
      },
    ],
  },
  {
    label: "Find Professionals",
    href: "/providers",
    // TODO: Fix/Remove Menu
    /*children: [
      {
        label: "All Professionals",
        href: "/providers",
      },
      {
        label: "Fitness Coach",
        href: "/providers/fitness-coach",
      },
      {
        label: "Lawn Tennis Coach",
        href: "/providers/lawn-tennis-coach",
      },
      {
        label: "Masseuse/Massage Therapist",
        href: "/providers/masseuse",
      },
      {
        label: "Dietician/Nutritionist",
        href: "/providers/nutritionist",
      },
      {
        label: "Physiotherapist",
        href: "/providers/physiotherapist",
      },
      {
        label: "Swimming Coach",
        href: "/providers/swimming-coach",
      },
      {
        label: "Yoga Coach",
        href: "/providers/yoga",
      },
    ],*/
  }, 
  {
    label: "WellFitly Blog",
    href: "/blog",
  },
];

function Nav() {
  const { isOpen, onToggle } = useDisclosure();
  const session = useSession();

  return (
    <Box mb={"60px"}>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        position="fixed"
        top="0"
        w="full"
        zIndex={2}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            as="a"
            href="/"
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"YesevaOne"}
            fontSize={"xl"}
            color={useColorModeValue("black", "white")}
          >
            WellFitly
          </Text>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {session ? (
            <Button
              id="btn-nav-dashboard"
              as={"a"}
              fontSize={"sm"}
              fontWeight={700}
              color={"black"}
              variant={"link"}
              href={"/dashboard"}
            >
              Dashboard
            </Button>
          ) : (
            <Button
              id="btn-nav-signup"
              as={"a"}
              fontSize={"sm"}
              fontWeight={700}
              color={"black"}
              variant={"link"}
              href={"/login"}
            >
              Log in
            </Button>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem, idx) => (
        <Box key={navItem.label}>
          <Popover
            trigger={"hover"}
            placement={"bottom-start"}
            id={`${idx}-${navItem.label}`}
          >
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = memo(({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "black" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"black"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
});

DesktopSubNav.displayName = 'DesktopSubNav';


const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
      position={"fixed"}
      top={"60px"}
      w={"full"}
      zIndex={2}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

MobileNav.displayName = 'MobileNav';

const MobileNavItem = memo(({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
});

MobileNavItem.displayName = 'MobileNavItem';

export default memo(Nav)