import { memo } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const ModalContainer = ({
  title,
  isOpen,
  onClose,
  isCentered = true,
  hasFooter = false,
  children,
}) => {
  return (
    <Modal isCentered={isCentered} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent>
        <ModalHeader fontFamily={"YesevaOne"}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {hasFooter && (
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default memo(ModalContainer);
